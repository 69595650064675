import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { main, bannerSpacer, icon, header, body, imageBy } from "../styles/index.module.css"
import Layout from "../components/layout/"
import Seo from "../components/seo";
import { useSiteMetadata } from "../hooks/use-site-metadata";

const IndexPage = () => {

  return (
    <main className={main}>
      <Layout>
        <div className={bannerSpacer} />
        <Container>
          <Row className="text-center text-white mb-5">
            <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }} className="mb-5">
              <StaticImage
                src="../images/icon.png"
                alt="Personal Logo"
                className={icon}
                placeholder="none"
              />
            </Col>
            <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }}>
              <h1 className={header}>Welcome</h1>
            </Col>
          </Row>
          <Row className="text-white">
            <Col xs={{ span: 10, offset: 2 }} sm={{ span: 10, offset: 1 }}>
              <p className={body}>
                The potential of people is as vast as the universe.
                We can do anything that we set our mind to and mine is set
                on developing beautiful, user-friendly applications
                with a minimalist approach.  I hope you enjoy
                this little portfolio of my life and work.  Thank you.
              </p>
            </Col>
          </Row>
        </Container>
        {/* <Button variant="dark" className={styles.gameButton}>Blast From The Past</Button> */}
        <small className={imageBy}>
          Photo by{' '}
          <a href="https://unsplash.com/@vorosbenisop?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank" rel="noopener noreferrer">
            Benjamin Voros
          </a>{' '}
          <sup>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
              <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
              <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
            </svg>
          </sup>
          on{' '}
          <a href="https://unsplash.com/s/photos/stars?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            target="_blank" rel="noopener noreferrer">
            Unsplash
          </a>{' '}
          <sup>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 32 32">
              <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
              <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
            </svg>
          </sup>
        </small>
      </Layout>
    </main>
  )
}

export const Head = ({ location }) => {
  const { title, author, description, siteUrl, image } = useSiteMetadata();
  const pageSeo = {
    pathname: location.pathname,
  }

  return (
    <Seo pageSeo={pageSeo}>
      <script type="application/ld+json" >
        {`
        {
          "@context": "https://schema.org/",
          "@type": "Organization",
          "url": "${siteUrl}",
          "logo": "${siteUrl}${image}",
          "name": "${title}",
          "author": "${author}",
          "description": "${description}"
        }
      `}
      </script>
    </Seo>
  )
}

export default IndexPage